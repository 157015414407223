var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "goods-reserve" } },
    [
      _c("HeadNav"),
      _vm._l(_vm.sort, function (item, index) {
        return _c(
          "yd-cell-group",
          { key: index },
          [
            _c("p", { staticClass: "goods-reserve-title" }, [
              _vm._v("申请明细：第" + _vm._s(index + 1) + "条"),
            ]),
            _c("div", { staticClass: "sort-box" }, [
              _c("span", [_vm._v("商品分类")]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.sort_id,
                      expression: "item.sort_id",
                    },
                  ],
                  staticClass: "goods-sort",
                  attrs: { autocomplete: "off", id: _vm.getSelect(index) },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          item,
                          "sort_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.changeProduct($event, index)
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("请选择一级分类"),
                  ]),
                  _vm.sort_list[0].id
                    ? _vm._l(_vm.sort_list, function (data, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: data.id } },
                          [_vm._v(_vm._s(data.title))]
                        )
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.good_cate_id,
                      expression: "item.good_cate_id",
                    },
                  ],
                  staticClass: "goods-sort",
                  attrs: { arrow: "", id: _vm.mySelect(index) },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          item,
                          "good_cate_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.changeProduct2($event, index)
                      },
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("请选择二级分类"),
                  ]),
                  _vm.sort_list[item.current].goods_sec_cate &&
                  (item.sort_id || item.sort_id === "0")
                    ? _vm._l(
                        _vm.sort_list[item.current].goods_sec_cate,
                        function (data, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: data.id } },
                            [_vm._v(_vm._s(data.title))]
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("yd-cell-item", [
              _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                _vm._v("商品名称"),
              ]),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.goods_id,
                      expression: "item.goods_id",
                    },
                  ],
                  staticClass: "goods-sort",
                  attrs: { slot: "right", arrow: "", id: _vm.myGoodsId(index) },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          item,
                          "goods_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function ($event) {
                        return _vm.changeProduct3($event, index)
                      },
                    ],
                  },
                  slot: "right",
                },
                [
                  _c("option", { attrs: { value: "", selected: "" } }, [
                    _vm._v("请选择商品"),
                  ]),
                  _vm.goods_list &&
                  _vm.goods_list[item.cate_current].goods &&
                  (item.good_cate_id || item.good_cate_id === "0")
                    ? _vm._l(
                        _vm.goods_list[item.cate_current].goods,
                        function (data, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: data.id } },
                            [_vm._v(_vm._s(data.name))]
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c("yd-cell-item", [
              (item.only_drew_num || item.only_drew_num == "0") &&
              (item.already_drew_num || item.already_drew_num == "0")
                ? _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                    _vm._v(
                      "数量(本月已领" +
                        _vm._s(item.already_drew_num) +
                        "，本次可领" +
                        _vm._s(item.only_drew_num) +
                        ")"
                    ),
                  ])
                : _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                    _vm._v("数量"),
                  ]),
              _c(
                "span",
                { attrs: { slot: "right" }, slot: "right" },
                [
                  _c("yd-spinner", {
                    attrs: {
                      max: item.only_drew_num || 1,
                      unit: "1",
                      val: index,
                    },
                    model: {
                      value: item.number,
                      callback: function ($$v) {
                        _vm.$set(item, "number", $$v)
                      },
                      expression: "item.number",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "p",
              {
                staticClass: "to-delete",
                on: {
                  click: function ($event) {
                    return _vm.toDelete(index)
                  },
                },
              },
              [_vm._v("删除")]
            ),
          ],
          1
        )
      }),
      _c("p", { staticClass: "add", on: { click: _vm.getAdd } }, [
        _vm._v("+添加"),
      ]),
      _c("p", { staticClass: "perch" }),
      _vm.is_commit
        ? _c("div", { staticClass: "commit-box" }, [
            _c("p", { staticClass: "go-commit", on: { click: _vm.goSubmit } }, [
              _vm._v("提交"),
            ]),
          ])
        : _c("div", { staticClass: "commit-box" }, [
            _c("p", { staticClass: "go-commits" }, [_vm._v("提交")]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }