<template>
  <div id="goods-reserve">
    <HeadNav />
    <yd-cell-group v-for="(item, index) in sort" :key="index">
      <p class="goods-reserve-title">申请明细：第{{ index + 1 }}条</p>
      <div class="sort-box">
        <span>商品分类</span>
        <select
          class="goods-sort"
          autocomplete="off"
          v-model="item.sort_id"
          @change="changeProduct($event, index)"
          :id="getSelect(index)"
        >
          <option value="" selected>请选择一级分类</option>
          <template v-if="sort_list[0].id">
            <option
              v-for="(data, index) in sort_list"
              :key="index"
              :value="data.id"
              >{{ data.title }}</option
            >
          </template>
        </select>
        <select
          class="goods-sort"
          arrow
          v-model="item.good_cate_id"
          @change="changeProduct2($event, index)"
          :id="mySelect(index)"
        >
          <option value="" selected>请选择二级分类</option>
          <template
            v-if="
              sort_list[item.current].goods_sec_cate &&
                (item.sort_id || item.sort_id === '0')
            "
          >
            <option
              v-for="(data, index) in sort_list[item.current].goods_sec_cate"
              :key="index"
              :value="data.id"
              >{{ data.title }}</option
            >
          </template>
        </select>
      </div>
      <yd-cell-item>
        <span slot="left">商品名称</span>
        <select
          slot="right"
          class="goods-sort"
          arrow
          v-model="item.goods_id"
          @change="changeProduct3($event, index)"
          :id="myGoodsId(index)"
        >
          <option value="" selected>请选择商品</option>
          <template
            v-if="
              goods_list &&
                goods_list[item.cate_current].goods &&
                (item.good_cate_id || item.good_cate_id === '0')
            "
          >
            <option
              v-for="(data, index) in goods_list[item.cate_current].goods"
              :key="index"
              :value="data.id"
              >{{ data.name }}</option
            >
          </template>
        </select>
      </yd-cell-item>
      <yd-cell-item>
        <span
          slot="left"
          v-if="
            (item.only_drew_num || item.only_drew_num == '0') &&
              (item.already_drew_num || item.already_drew_num == '0')
          "
          >数量(本月已领{{ item.already_drew_num }}，本次可领{{
            item.only_drew_num
          }})</span
        >
        <span slot="left" v-else>数量</span>
        <span slot="right">
          <yd-spinner
            :max="item.only_drew_num || 1"
            unit="1"
            v-model="item.number"
            :val="index"
          ></yd-spinner>
        </span>
      </yd-cell-item>
      <p class="to-delete" @click="toDelete(index)">删除</p>
    </yd-cell-group>
    <p class="add" @click="getAdd">+添加</p>
    <p class="perch"></p>
    <div class="commit-box" v-if="is_commit">
      <p class="go-commit" @click="goSubmit">提交</p>
    </div>
    <div class="commit-box" v-else>
      <p class="go-commits">提交</p>
    </div>
  </div>
</template>

<script>
import { getGoodsCate, getGoods, isSubmitGoodsOrder } from "@/services/my";
import HeadNav from "@/components/common/HeadNav.vue";

export default {
  components: {
    HeadNav
  },
  data() {
    return {
      show1: false,
      model1: "",
      good_sort: "",
      sort: [
        {
          current: 0, //已选中的一级分类下标
          cate_current: 0,
          sort_id: "",
          good_cate_id: "", //二级分类id
          goods_id: "", //商品id
          number: 1, //申请数量
          already_drew_num: "",
          only_drew_num: "",
          name: "",
          goods: []
        }
      ],
      good_cate_id: "", //二级分类id
      value: ["", "", ""],
      sort_two_list: [{ id: "", title: "暂无数据" }], //二级分类
      sort_list: [{ goods_sec_cate: [], id: "" }], //一级分类
      goods_list: [
        {
          goods: []
        }
      ], //商品列表
      is_commit: true //防止提交多次点击
    };
  },
  created() {
    const businessInfo = JSON.parse(
      window.localStorage.getItem("businessInfo")
    );
    this.getGoodsCateList(businessInfo.id);
  },
  methods: {
    async goSubmit() {
      let goods = [];
      let is_submit = [];
      this.sort.filter(item => {
        if (!item.goods_id) {
          is_submit.push(false);
        } else {
          is_submit.push(true);
        }
      });
      this.sort.map(item => {
        if (is_submit.indexOf(false) !== -1) {
          this.$dialog.toast({
            mes: "商品不可为空请挑选以后提交",
            timeout: 1500
          });
        } else {
          goods.push({
            goods_id: item.goods_id,
            number: item.number,
            name: item.name
          });
        }
      });
      if (is_submit.indexOf(false) == -1 && this.is_commit) {
        this.is_commit = false;
        const res = await isSubmitGoodsOrder(goods);
        setTimeout(() => {
          this.is_commit = true;
        }, 3500);
        this.$dialog.toast({
          mes: res.msg,
          timeout: 1500
        });
        if (res.code == 0) {
          setTimeout(() => {
            this.$router.push({
              path: "/reserve_details"
            });
          }, 1600);
        }
      }
    },
    mySelect(i) {
      return "mySelect" + i;
    },
    myGoodsId(i) {
      return "goods-id" + i;
    },
    getSelect(i) {
      return "select-id" + i;
    },
    //获取物品
    async getGoodsList() {
      const res = await getGoods();
      if (res.code == 0 && res.data.length>0) {
        this.goods_list = res.data;
      }
    },
    changeProduct(event, index) {
      this.sort[index].good_cate_id = "";
      let initialize = document.getElementById("mySelect" + index); //mySelect是select 的Id
      initialize.options[0].selected = true;
      this.sort_list.map((item, idx) => {
        if (item.id == event.target.value) {
          this.sort[index].current = idx;
          this.sort[index].sort_id = event.target.value;
          console.log("1");
        }
      });
    },
    changeProduct2(event, index) {
      console.log(index, "index");
      document.getElementById("goods-id" + index).value = "";
      this.sort[index].goods_id = "";
      this.sort[index].already_drew_num = "";
      this.sort[index].only_drew_num = "";
      this.goods_list.map((item, idx) => {
        if (item.id == event.target.value) {
          this.sort[index].cate_current = idx;
          this.sort[index].good_cate_id = event.target.value;
          this.sort[index].goods = this.goods_list[idx].goods;
        }
      });
    },
    changeProduct3(event, index) {
      // document.getElementById("goods-id" + index).value = ""
      this.sort[index].goods_id = event.target.value;
      if (!event.target.value) {
        this.sort[index].already_drew_num = "";
      }
      let is_add = 0;
      this.sort.filter(item => {
        if (item.goods_id == event.target.value) {
          is_add++;
        }
      });
      let list = this.sort[index].goods;
      if (is_add == 1) {
        list.map(item => {
          if (item.id == event.target.value) {
            this.sort[index].goods_id = event.target.value;
            this.sort[index].name = item.name;
            this.sort[index].already_drew_num = item.already_drew_num;
            this.sort[index].only_drew_num = item.only_drew_num;
          }
        });
      } else {
        document.getElementById("goods-id" + index).value = "";
        this.sort[index].goods_id = "";
        this.$dialog.toast({
          mes: "该商品已存在，请选择其他商品",
          timeout: 1500
        });
      }
    },
    //获取分类
    async getGoodsCateList(id) {
      const res = await getGoodsCate(id);
      if (res.code == 0 && res.data.length) {
        this.sort_list = res.data;
        console.log(this.sort_list);
        res.data.map(item => {
          this.sort_two_list = item.goods_sec_cate;
          console.log(this.sort_two_list, "goods_sec_cate");
        });
      }
      this.getGoodsList();
    },
    // 增
    getAdd() {
      const sort = {
        current: 0, //已选中的一级分类下标
        cate_current: 0,
        sort_id: "",
        good_cate_id: "", //二级分类id
        goods_id: "", //商品id
        number: 1, //申请数量
        already_drew_num: "",
        only_drew_num: "",
        goods: []
      };
      this.sort.push(sort);
    },
    // 删
    toDelete(i) {
      console.log(i, "index");
      if (this.sort.length > 1) {
        this.sort.splice(i, 1);
        console.log(this.sort, "list");
      } else {
        this.$dialog.toast({
          mes: "仅为一条时不可删除！",
          timeout: 1500
        });
      }
    }
  }
};
</script>
<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";
#goods-reserve {
  .sort-box {
    margin-left: 0.24rem;
    color: #555;
    font-size: 0.28rem;
    white-space: nowrap;
    border-bottom: 1px solid #e4e4e4;
    select {
      height: 0.9rem;
      border: none;
      color: #a9a9a9;
      font-size: 0.28rem;
      padding-left: 0.26rem;
      width: 40%;
    }
  }
  .yd-cell-right select {
    padding-left: 0.3rem;
    max-width: 3rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .goods-reserve-title {
    font-size: 0.24rem;
    padding: 0.26rem 0.26rem 0.16rem;
    color: #a9a9a9;
    background: #f5f5f5;
  }
  .add {
    // background: var(--main-color);
    background: #fff;
    color: #00b7ee;
    font-size: 0.32rem;
    padding: 0.26rem 0;
    text-align: center;
  }
  .to-delete {
    text-align: center;
    padding: 0.26rem 0;
    color: red;
  }
  .perch {
    height: 3rem;
    width: 100%;
    background: #f5f5f5;
  }
  .commit-box {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    .go-commit,
    .go-commits {
      width: 90%;
      padding: 0.26rem 0;
      background: #409eff;
      border-radius: 0.36rem;
      font-size: 0.36rem;
      color: #fff;
      font-weight: 600;
      text-align: center;
      margin: auto;
    }
    .go-commits {
      background: #ccc;
    }
  }
}
</style>
